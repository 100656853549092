import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Container, Center } from '../../styles/PrivacyPolicyStyle';
import ScrollToTop from '../../utils/ScrollToTop';

const PrivacyPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Container>
        <Header />
        <Center>
          <h1>Privacy Policy</h1>
          <h2>At BrighterHeads, we are committed to protecting your privacy and ensuring
            the security of your personal information. This privacy policy outlines how
            we collect, use, and protect the data you provide to us.</h2>

          <h3><span>1. </span>Information We Collect:</h3>
          <p>We collect the following information through our website's whitelist:</p>
          <ul>
            <li>Full Name</li>
            <li>Age</li>
            <li>Gender</li>
            <li>Email</li>
            <li>Country</li>
            <li>City</li>
            <li>Digital Wallet Address</li>
          </ul>

          <h3><span>2. </span>Purpose of Data Collection:</h3>
          <p>The data collected is solely used for the following purposes:</p>
          <ul>
            <li>
              Dropping NFTs and other digital assets into the provided digital wallet
              addresses.
            </li>
            <li>
              Conducting internal studies to understand the profile of individuals
              interested in our project.
            </li>
            <li>
              We may also use your email address to communicate important updates, news,
              and notifications related to the project and your participation in the whitelist.
            </li>
          </ul>

          <h3><span>3. </span>Data Security:</h3>
          <p>We employ industry-standard security measures to protect your personal information.
            We have implemented appropriate physical, electronic, and managerial procedures to
            safeguard and secure the data we collect.</p>

          <h3><span>4. </span>Data Sharing:</h3>
          <p>We do not share your personal information with third parties, except as required
            by law or with your explicit consent.</p>

          <h3><span>5. </span>Data Retention:</h3>
          <p>We retain your personal information for as long as necessary to fulfill the purposes
            outlined in this privacy policy, or until you request its deletion.</p>
          <p>By providing your information to us, you acknowledge and consent to the collection,
            use, and storage of your data as described in this policy.</p>
        </Center>
        <Footer />
      </Container>
    </>
  )
};

export default PrivacyPolicy;
