import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: auto;
`;

export const Center = styled.div`
  width: 90%;
  max-width: 1440px;
  margin-bottom: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & * {
    font-family: "Raleway", sans-serif;
  }

  h1 {
    font-size: 2.5rem;
    margin-top: 50px;
    margin-bottom: 30px;
    border-bottom: 4px solid #336699;
    padding-bottom: 5px;
  }

  h2 {
    max-width: 1000px;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
  }

  h3 {
    align-self: flex-start;
    margin-top: 50px;
    margin-bottom: 12px;
    font-size: 1.5rem;

    & span {
      color: #336699;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    margin: 10px 0;
    align-self: flex-start;
  }

  ul {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 10px;
    font-size: 20px;
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 22px;
    }

    p, 
    li {
      font-size: 16px;
      line-height: 30px;
    }

    ul {
      gap: 0.5rem;
    }
  }
`;